import React from 'react';
import privacyGradient from "./PrivacyAssets/gradient.png";
import privacyLogo from "./PrivacyAssets/logo.svg";
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
    const navigate = useNavigate();
    return (
        <div className="sans" style={{ fontSize: '10px', backgroundColor: 'white', height: '100vh', overflow: 'scroll' }}>
            <article className="page" style={{ fontSize: '1.6rem', lineHeight: '1.5', margin: '3.2rem auto', maxWidth: '90rem', color: 'rgb(55, 53, 47)' }}>
                <div>
                    <a onClick={() => navigate('/')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Home</a>
                    <span style={{ margin: '0 0.8rem' }}>•</span>
                    <a onClick={() => navigate('/login')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Log In</a>
                </div>
                <img className="page-cover-image" src={privacyGradient} style={{ objectPosition: 'center 80.3%', maxWidth: '900px' }} />
                <div className="page-header-icon page-header-icon-with-cover" style={{ objectPosition: 'center 80.3%', maxWidth: '900px' }}>
                    <img className="icon" src={privacyLogo} style={{ maxWidth: '5em', maxHeight: '5em' }} />
                </div>
                <h1 className="page-title" style={{ fontSize: '4rem', fontWeight: '700', marginTop: '0', marginBottom: '1.2rem' }}>Maester AI Privacy Policy</h1>

                <div className="page-body">
                    <p><em>Effective Date: August 1, 2024</em></p>
                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>1. Introduction</strong></h2>
                    <p>Maester AI ("we," "us," "our") is committed to protecting the privacy and security of our customers' ("you," "your," "Customer") personal and business information. This Privacy Policy outlines how we collect, use, store, and share your information when you access or use our services. By engaging with Maester AI, you acknowledge and agree to the practices described in this policy.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>2. Information We Collect</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>2.1. Personal Information</strong></h3>
                    <p>We may collect personal information that you provide to us, such as your name, email address, phone number, and any other contact details.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>2.2. Business Data</strong></h3>
                    <p>We collect business-related data that you provide when using our services, including performance metrics, analytics, and other data necessary for the functionality of Maester AI.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>2.3. Usage Data</strong></h3>
                    <p>We automatically collect information about your interactions with our platform, such as IP address, device information, browser type, and usage patterns.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>2.4. Cookies and Tracking Technologies</strong></h3>
                    <p>We use cookies and similar tracking technologies to enhance your experience on our platform. You can manage your cookie preferences through your browser settings, but disabling cookies may affect the functionality of our services.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>3. How We Use Your Information</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>3.1. Service Delivery</strong></h3>
                    <p>We use the information we collect to provide, maintain, and improve our services, including delivering AI-driven analysis, supporting customer inquiries, and enhancing platform performance.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>3.2. Communication</strong></h3>
                    <p>We may use your contact information to send you updates, promotional materials, and other communications related to Maester AI. You can opt-out of marketing communications at any time by following the unsubscribe instructions provided in the emails.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>3.3. Legal Compliance</strong></h3>
                    <p>We may use your information to comply with legal obligations, enforce our agreements, and protect our rights and the rights of others.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>4. Data Storage and Security</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>4.1. Data Storage</strong></h3>
                    <p>Your data is stored and processed using secure infrastructures provided by our trusted third-party partners:</p>
                    <ul>
                        <li><a href="https://aws.amazon.com/privacy/">Amazon Web Services (AWS)</a>: Our primary cloud storage and computing provider. Data stored on AWS is subject to AWS's privacy policies.</li>
                        <li><a href="https://openai.com/enterprise-privacy/">OpenAI API</a>: Used for advanced language processing. Data processed through OpenAI's API is governed by OpenAI's privacy policies.</li>
                        <li><a href="https://www.datastax.com/legal/datastax-website-privacy-policy">DataStax Astra DB</a>: Used for managing and storing database information. Data handled by Astra DB is subject to DataStax's privacy policies.</li>
                    </ul>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>4.2. Security Measures</strong></h3>
                    <p>We implement industry-standard security measures, including encryption, access controls, and regular security assessments, to protect your data from unauthorized access, alteration, disclosure, or destruction.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>4.3. Data Retention</strong></h3>
                    <p>We retain your data for as long as necessary to fulfill the purposes outlined in this policy or as required by law. Upon termination of your use of Maester AI, we will delete your data from our systems within a reasonable timeframe, unless retention is required by legal obligations.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>5. Data Sharing</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>5.1. Third-Party Service Providers</strong></h3>
                    <p>We may share your information with third-party service providers who assist us in delivering our services. These providers are bound by confidentiality agreements and are prohibited from using your data for any purpose other than providing services to Maester AI.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>5.2. Legal Obligations and Protection of Rights</strong></h3>
                    <p>We may disclose your information if required by law or in response to a legal process, to protect our rights, or to prevent harm to others.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>5.3. Business Transfers</strong></h3>
                    <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>6. Data Collection</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>6.1. Data Storage</strong></h3>
                    <p>We collect data only when you request it, specifically related to Google Sheets and Google Drive files.</p>
                    <ul>
                        The scopes we use include:
                        <li><a href="https://www.googleapis.com/auth/drive.file">https://www.googleapis.com/auth/drive.file</a>: Read-only access to your Google Drive, so we can download and reference the files you choose to share with us.</li>
                        <li><a href="https://www.googleapis.com/auth/spreadsheets">https://www.googleapis.com/auth/spreadsheets</a>: Read-only access to your Google Sheets, so we can download and analyze the data you choose to share with us.</li>
                        <li><a href="https://www.googleapis.com/auth/userinfo.email">https://www.googleapis.com/auth/userinfo.email</a>: Used to access your email address so we can correlate the data between your email and sheets.</li>
                    </ul>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>7. Customer Responsibilities</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>7.1. Accuracy of Information</strong></h3>
                    <p>While Maester AI strives to provide accurate and reliable analyses based on the data you provide, we do not guarantee the accuracy, completeness, or reliability of the information generated. You are responsible for independently verifying any conclusions, insights, or recommendations derived from the use of Maester AI, particularly when they relate to critical business decisions or other important matters.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>7.2. Verification of Important Information</strong></h3>
                    <p>Users are strongly encouraged to cross-check any significant or critical information provided by Maester AI with other reliable sources before making decisions based on that information. Maester AI should be used as a supplementary tool, and not as the sole basis for any major business or operational decisions.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>7.3. Data Submission</strong></h3>
                    <p>You are responsible for ensuring that you do not input sensitive or confidential information into Maester AI. We recommend avoiding the submission of personally identifiable information (PII) or any other data that could compromise the privacy or security of individuals or entities.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>7.4. Security Measures</strong></h3>
                    <p>You should implement appropriate security measures to protect your own data and systems when using Maester AI.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>8. Your Rights</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>8.1. Access and Correction</strong></h3>
                    <p>You have the right to access and correct the personal information we hold about you. You can update your information by contacting us at tim@maester.ai.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>8.2. Data Deletion</strong></h3>
                    <p>You may request the deletion of your data at any time by contacting us at tim@maester.ai. We will comply with such requests in accordance with applicable laws and within a reasonable timeframe.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>8.3. Opt-Out of Marketing Communications</strong></h3>
                    <p>You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in our emails or by contacting us at tim@maester.ai.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>9. Product Limitations and Disclaimer</strong></h2>
                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>9.1. Product Limitations</strong></h3>
                    <p>Maester AI is an early-stage platform provided on an "as-is" and "as-available" basis. We make no warranties, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

                    <h3 style={{ fontSize: '2.4rem', marginTop: '2.4rem', marginBottom: '1.2rem' }}><strong>9.2. Disclaimer</strong></h3>
                    <p>While we endeavor to minimize bugs and technical issues, Maester AI cannot guarantee a bug-free experience. Technical issues may arise, and while we will make reasonable efforts to resolve them, we cannot guarantee that all issues will be addressed.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>10. Modifications to This Policy</strong></h2>
                    <p>Maester AI reserves the right to modify this Privacy Policy at any time. Any changes will be posted on the Maester AI website, and we will notify you of significant changes either through the platform or via email. Continued use of Maester AI following any modifications constitutes acceptance of those changes.</p>

                    <h2 style={{ fontSize: '3rem', marginTop: '3rem', marginBottom: '1.6rem' }}><strong>11. Contact Information</strong></h2>
                    <p>For questions or concerns regarding this Privacy Policy, please contact us at: <a href="mailto:tim@maester.ai">tim@maester.ai</a></p>
                </div>
            </article>
        </div>
    );
};

export default Privacy;