import { get, put, post } from 'aws-amplify/api';


//API Calls
//Get HubSpot Fields as dict of objects and properties, get user presets
export const getHubSpotObjectToFields = async (jwtToken) => {
  const apiFields = await (await get({
    apiName: 'MaesterV3API',
    path: '/api/v3/hubspot/fields',
    options: {
      headers: { Authorization: jwtToken },
      body: { redirect_uri: window.location.origin + '/hubspot-callback' }
    }
  }).response).body.json();
  let allFields = {};
  let selectedFields = {};

  for (let object in apiFields) {
    allFields[object] = Object.keys(apiFields[object]);

    for (let field of Object.keys(apiFields[object])) {
      if (!selectedFields[object]) {
        selectedFields[object] = {};
      }
      selectedFields[object][field] = apiFields[object][field];
    }
  }

  return [allFields, selectedFields];
}


export const getHubSpotAuthCodeAndExport = async (jwtToken, hubspotCode) => {
  if (hubspotCode) {
    console.log('hubspot code:', hubspotCode)
    try {
      const response = await post({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/auth',
        options: {
          headers: { Authorization: jwtToken },
          body: {
            hubspotCode: hubspotCode,
            redirect_uri: window.location.origin + '/hubspot-callback'
          }
        }
      }).response;
      console.log(response);

      const export_response = await put({
        apiName: 'MaesterV3API',
        path: '/api/v3/hubspot/direct-export',
        options: {
          headers: { Authorization: jwtToken },
          body: {}
        }
      }).response;
      console.log(export_response);
    } catch (error) {
      console.error("Failed to save HubSpot auth code", error);
    }
  }
}

export const connectHubspot = async (jwtToken, setConnectionStatus) => {
  setConnectionStatus("loading");
  try {
    await get({
      apiName: 'MaesterV3API',
      path: '/api/v3/hubspot/direct-export',
      options: {
        headers: { Authorization: jwtToken },
      }
    }).response;
    setConnectionStatus("success");
    return true;
  } catch (error) {
    setConnectionStatus("fail");
    return false;
  }
}

export const handleHubspotRefresh = (jwtToken, setSnackbar) => {
  setSnackbar("refreshing data, this may take a few minutes", "loading");
  put({
    apiName: 'MaesterV3API',
    path: '/api/v3/hubspot/direct-export',
    options: {
      headers: { Authorization: jwtToken },
      body: {}
    }
  }).response.then(response => {
    console.log(response);
  }).catch(error => {
    console.error(error);
  }).finally(() => {
    console.log("done updating");
    setTimeout(() => {
      console.log("in timeout");
      setSnackbar("refresh complete!", "complete");
    }, 153080);
  });
}

export const handleHubspotSubmit = async (jwtToken, setSnackbar, selectedFields) => {
  setSnackbar("updating fields...", "loading");
  console.log("trying to update");

  try {
    const response = await (await (put({
      apiName: 'MaesterV3API',
      path: '/api/v3/hubspot/fields',
      options: {
        headers: { Authorization: jwtToken },
        body: { fields: selectedFields }
      }
    })).response).body.json();

    if (response === "Successfully updated fields") {
      setSnackbar("fields updated!", "complete");
    }
  } catch (error) {
    setSnackbar("failed to save fields", "error");
    console.error(error);
  } finally {
    console.log("done");
  }
};
