import React from 'react';

const FormattingSubmenu = ({ chartFormatting, setChartFormatting, setActiveContextFile, setContextSubmenu, handleSetContext, jwtToken, setSnackbar, activeContextFile, activeContext, getContext, setOrgContext, submenu }) => {
    const checkSelected = (arg1, arg2) => {
        if (chartFormatting && chartFormatting[arg1] && chartFormatting[arg1][arg2]) {
            return 'selected';
        }
        return '';
    };

    const toggleFormatting = (arg1, arg2) => {
        setChartFormatting(prevState => ({
            ...prevState,
            [arg1]: {
                ...prevState[arg1],
                [arg2]: !prevState[arg1][arg2]
            }
        }));
    };

    const updateContextAndHandleSet = async () => {
        setSnackbar("Updating formatting preferences", "loading");
        // Convert chartFormatting back to a string
        const chartFormattingStr = JSON.stringify(chartFormatting, null, 0);

        // Remove newlines from chartFormattingStr
        const formattedchartFormattingStr = chartFormattingStr.replace(/\n/g, '');

        // Define the pattern to match the chart formatting section
        const chartFormattingPattern = /(CHART FORMATTING:\n\s*)({.*})/;

        // Replace the relevant part of activeContext
        const updatedActiveContext = activeContext.replace(chartFormattingPattern, `$1${formattedchartFormattingStr}`);

        // Call handleSetContext with the updated activeContext
        await handleSetContext(jwtToken, setSnackbar, activeContextFile, updatedActiveContext);
        setOrgContext(updatedActiveContext);
        setActiveContextFile(null);
        setContextSubmenu("settings");
        setSnackbar("Update completed!", "complete");
    };

    // In your component
    <i className="material-icons-outlined" onClick={updateContextAndHandleSet}>check</i>

    return (
        <div key="formatting" className={submenu === "formatting" ? "menu small-details" : "menu hidden"}>
            <div className="section column wrap">
                <div className="section-header"> Company Formatting </div>
                <div className="section-detail subheading"> Indicate your preferences for chart formatting here. Maester will try to incorporate your selections in all charts it produces </div>
                <div className="section-header"> Title </div>
                <div className="section row wrap">
                    <button className={`chip short ${checkSelected("Title", "Bold")}`} onClick={() => toggleFormatting("Title", "Bold")}>
                        <i className="material-icons-outlined ">format_bold</i>
                        <div className="label" style={{ fontWeight: "bold" }}>Bold</div>
                    </button>
                    <button className={`chip short ${checkSelected("Title", "Underline")}`} onClick={() => toggleFormatting("Title", "Underline")}>
                        <i className="material-icons-outlined ">format_underline</i>
                        <div className="label" style={{ textDecoration: "underline" }}>Underline</div>
                    </button>
                    <button className={`chip short ${checkSelected("Title", "Italicize")}`} onClick={() => toggleFormatting("Title", "Italicize")}>
                        <i className="material-icons-outlined ">format_italic</i>
                        <div className="label" style={{ fontStyle: "italic" }}>Italicize</div>
                    </button>
                    <div className="tooltip chip-short"><span className="tooltiptext top">Company colors on charts</span>
                        <button className={`chip short ${checkSelected("Title", "Colors")}`} onClick={() => toggleFormatting("Title", "Colors")}>
                            <i className="material-icons-outlined primary">palette</i>
                            <div className="label">Colors</div>
                        </button>
                    </div>
                </div>
                <div className="section-header"> X Axis Labels </div>
                <div className="section row wrap">
                    <button className={`chip short ${checkSelected("xAxis", "Bold")}`} onClick={() => toggleFormatting("xAxis", "Bold")}>
                        <i className="material-icons-outlined ">format_bold</i>
                        <div className="label" style={{ fontWeight: "bold" }}>Bold</div>
                    </button>
                    <button className={`chip short ${checkSelected("xAxis", "Underline")}`} onClick={() => toggleFormatting("xAxis", "Underline")}>
                        <i className="material-icons-outlined ">format_underline</i>
                        <div className="label" style={{ textDecoration: "underline" }}>Underline</div>
                    </button>
                    <button className={`chip short ${checkSelected("xAxis", "Italicize")}`} onClick={() => toggleFormatting("xAxis", "Italicize")}>
                        <i className="material-icons-outlined ">format_italic</i>
                        <div className="label" style={{ fontStyle: "italic" }}>Italicize</div>
                    </button>
                    <div className="tooltip chip-short"><span className="tooltiptext top">Company colors on charts</span>
                        <button className={`chip short ${checkSelected("xAxis", "Colors")}`} onClick={() => toggleFormatting("xAxis", "Colors")}>
                            <i className="material-icons-outlined primary">palette</i>
                            <div className="label">Colors</div>
                        </button>
                    </div>
                </div>
                <div className="section-header"> Y Axis Labels </div>
                <div className="section row wrap">
                    <button className={`chip short ${checkSelected("yAxis", "Bold")}`} onClick={() => toggleFormatting("yAxis", "Bold")}>
                        <i className="material-icons-outlined ">format_bold</i>
                        <div className="label" style={{ fontWeight: "bold" }}>Bold</div>
                    </button>
                    <button className={`chip short ${checkSelected("yAxis", "Underline")}`} onClick={() => toggleFormatting("yAxis", "Underline")}>
                        <i className="material-icons-outlined ">format_underline</i>
                        <div className="label" style={{ textDecoration: "underline" }}>Underline</div>
                    </button>
                    <button className={`chip short ${checkSelected("yAxis", "Italicize")}`} onClick={() => toggleFormatting("yAxis", "Italicize")}>
                        <i className="material-icons-outlined ">format_italic</i>
                        <div className="label" style={{ fontStyle: "italic" }}>Italicize</div>
                    </button>
                    <div className="tooltip chip-short"><span className="tooltiptext top">Company colors on charts</span>
                        <button className={`chip short ${checkSelected("yAxis", "Colors")}`} onClick={() => toggleFormatting("yAxis", "Colors")}>
                            <i className="material-icons-outlined primary">palette</i>
                            <div className="label">Colors</div>
                        </button>
                    </div>
                </div>
                <div className="section-header"> Grid Lines </div>
                <div className="section row wrap">
                    <button className={`chip short ${checkSelected("GridLines", "Enabled")}`} onClick={() => toggleFormatting("GridLines", "Enabled")}>
                        <i className="material-icons-outlined ">grid_4x4</i>
                        <div className="label">Grid Lines</div>
                    </button>
                </div>
            </div>
            <div className="submit-buttons">
                <div>
                    <i className="material-icons-outlined" onClick={() => { setActiveContextFile(null); setContextSubmenu(null); }} >clear</i>
                    <i className="material-icons-outlined" onClick={() => updateContextAndHandleSet()} >check</i>
                </div>
            </div>
        </div>
    );
};

export default FormattingSubmenu;