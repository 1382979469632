import { signOut } from "aws-amplify/auth";

const signOutFn = async (navigate) => {
    try {
        await signOut();
        navigate('/');
    } catch (error) {
        //TODO: Error Snackbar
        console.log('error signing out: ', error);
    }
};

export default signOutFn;