import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// export function AmplifyCallbackHandler() {
//     useEffect(() => {
//         // Extract 'code' from the URL, authenticate with Amplify, then redirect
//         const code = new URLSearchParams(window.location.search).get('code');
//         if (code) {
//             console.log('Auth code shouldnt see this', code);
//             Auth.federatedSignIn({ provider: 'Cognito', code })
//                 .then(() => {
//                     // Redirect to desired authenticated route
//                     window.location.href = '/chat';
//                 })
//                 .catch(err => {
//                     console.error('Amplify login error:', err);
//                     window.location.href = '/';
//                 });
//         }
//     }, []);

//     return <div>Loading...</div>; // Show a loading message or spinner
// }

export function HubspotCallbackHandler() {
    const navigate = useNavigate();

    useEffect(() => {
        // Extract 'code' from the URL, process with your HubSpot handling logic
        const code = new URLSearchParams(window.location.search).get('code');
        if (code) {
            console.log('HubSpot code:', code);
            // Process HubSpot authentication, similar to Amplify but using HubSpot API
            console.log('Process HubSpot code here');
            // After processing, redirect as needed
            navigate('/chat', { state: { service: "hubspot", authCode: code } });
        }
    }, []);

    return <div>Loading...</div>; // Show a loading message or spinner
}

export function GoogleCallbackHandler() {
    const navigate = useNavigate();
    useEffect(() => {
        // Extract 'code' from the URL, process with your Google handling logic
        const code = new URLSearchParams(window.location.search).get('code');
        if (code) {
            console.log('Google code:', code);
            // Process Google authentication, similar to Amplify but using Google API
            console.log('Process Google code here');
            // Google Step 4: After processing, redirect as needed with authCode (check Chat)
            navigate('/chat', { state: { service: "google", authCode: code } });
        }
    }, []);

    return <div>Loading...</div>; // Show a loading message or spinner
}
