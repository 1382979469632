import React, { useState, useEffect } from 'react';
import { confirmSignIn, confirmResetPassword, getCurrentUser } from "aws-amplify/auth";
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import App from '../App';
import logoSmall from "../../assets/small-logo.png";
import "../../styles/screens/Login/Login.css";

function ChangePassword() {
    const locationState = useLocation().state;
    const { username, verificationCode } = locationState || {};
    const [password, setPassword] = useState('');
    const [passwordVerify, setPasswordVerify] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordVerify, setShowPasswordVerify] = useState(false);
    const [status, setStatus] = useState('');
    const navigate = useNavigate();
    const handleTogglePassword = (verify = false) => {
        if (verify) {
            setShowPasswordVerify(!showPasswordVerify);
        }
        else {
            setShowPassword(!showPassword);
        }
    };

    useEffect(() => {
        if (!username) {
            navigate("/forgot-password");
        }
    }, []);

    const handleSubmit = async () => {

        if (username && verificationCode && password === passwordVerify) {
            console.log(username, verificationCode, password);
            await confirmResetPassword({
                username: username,
                confirmationCode: verificationCode,
                newPassword: password,
            });
            navigate('/login');
        }

        else if (username && password === passwordVerify) {
            console.log(username, verificationCode, password);
            try {
                await confirmSignIn({
                    challengeResponse: password,
                });
            }
            catch (error) {
                console.error('Error confirming sign in', error);
                setStatus('failed');
                return;
            }
            navigate('/login');
        }

        else {
            setStatus('failed');
        }
    }
    return (
        <div className='login-container'>
            <div className='login'>
                <img src={logoSmall} alt="Maester" className={`logo`} />
                <form className='login-input-container'>
                    <div className='header-label'>Set a new password to log into Maester.</div>
                    <div className='password-input-container'>
                        <input className='login-input' autoComplete='new-password' type={showPassword ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                        <button className='password-button material-icons-outlined' onClick={(e) => { e.preventDefault(); handleTogglePassword(); }}>
                            {showPassword ? "visibility" : "visibility_off"}
                        </button>
                    </div>
                    <div className='password-input-container'>
                        <input className='login-input' autoComplete='new-password' type={showPasswordVerify ? "text" : "password"} name="passwordVerify" value={passwordVerify} onChange={(e) => setPasswordVerify(e.target.value)} placeholder="Verify Password" />
                        <button className='password-button material-icons-outlined' onClick={(e) => { e.preventDefault(); handleTogglePassword(true); }}>
                            {showPasswordVerify ? "visibility" : "visibility_off"}
                        </button>
                    </div>
                </form>
                <button className='login-button' onClick={() => handleSubmit()}>Submit</button>
                <label className={`status ${status}`} >
                    {status === "failed" ? "Password Change Failed" : " "}
                    <br />
                    {status === "failed" ? "Please make sure your passwords match and try again." : " "}
                    <br /><br />
                    {status === "failed" ? "Passwords must have one of each: uppercase letter, lowercase character, number, special character, and be longer than 8 characters." : " "}
                </label>
            </div>

        </div>
    );
}

export default ChangePassword;


