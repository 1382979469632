import { get, put } from 'aws-amplify/api';

//Include Get Context, Set Context, Per File Context
//TODO: Change context in the backend to return a dictionary of contexts, top level should be called "Organization Level Context"


export const getContext = async (jwtToken, setSnackbar, contextList = null) => {
    try {
        const queryParams = {}
        if (contextList) {
            contextList.forEach(file => {
                queryParams.files = file;
            });
            setSnackbar("loading context for : " + contextList.join(", "), "loading");
        }
        const returnedContext = await (await get({
            apiName: 'MaesterV3API',
            // path: `/api/v3/explanation?${queryParams.toString()}`,
            path: '/api/v3/explanation',
            options: {
                headers: { Authorization: jwtToken },
                queryParams: queryParams
            }
        }).response).body.json();
        //Don't wanna do context msg for org level
        if (contextList) {
        setSnackbar("context loaded!", "complete");
        }
        return returnedContext;
    } catch (error) {
        console.error("Failed to get explanation. If this is a new organization, be sure to create an organization level context.", error);
        return null;
    }
}


export const handleSetContext = async (jwtToken, setSnackbar, fileToUpdate, context) => {
    setSnackbar("updating context...", "loading");
    try {
        let body = { explanation: context}
        if (fileToUpdate !== 'Organization Level Context')
            body.file = fileToUpdate;

        await put({
            apiName: 'MaesterV3API',
            path: '/api/v3/explanation',
            options: {
                headers: { Authorization: jwtToken },
                body: body
            }
        }).response;
        setSnackbar("context updated!", "complete");
        return context;
    } catch (error) {
        setSnackbar("failed to update context, please try again", "error");
        return null;
    }
};
