import { DescriptionOutlined, UploadFileOutlined, Edit, DeleteOutline, CheckCircleOutline } from "@mui/icons-material";
import { handleFilesUpload, handleDeleteFile } from "../functions/api/Files";
import { googleSheetsLogo } from "../assets/icons/googleSheetsLogo";
import { SvgIcon } from "@mui/material";
import { hubspotLogo } from "../assets/icons/hubspotLogo";


//Rendering uploaded files
export const renderFiles = (jwtToken, setSnackbar, setFileNames, fileNames, setActiveContextFile, setContextSubmenu, activeButton, newChat, getAllThreads, setIsSendDisabled, isSendDisabled, setThreads) => {
  return (
    fileNames.map((filename, index) => (
      <div key={index} className={`tooltip`}><span className="tooltiptext top">{filename}</span>
        <button className={`button long ${activeButton === "uploaded" ? "" : "hidden"}`}>
          <i className="material-icons-outlined">description</i>
          <span className={`label open`}>
            {filename}
          </span>
          <i className="material-icons-outlined clickable" disabled = {isSendDisabled} onClick={() => { setActiveContextFile(filename); setContextSubmenu("files") }} >edit</i>
          <i className="material-icons-outlined clickable" disabled = {isSendDisabled} onClick={() => handleDeleteFile(jwtToken, setSnackbar, filename, setFileNames, newChat, getAllThreads, setIsSendDisabled, setThreads)} >delete</i>
        </button>
      </div>)));
};

export const renderUploadFileButton = (jwtToken, setSnackbar, length, setFileNames, fileNames, activeButton = null) => {
  return (<div className={`tooltip fullwidth ${activeButton === "uploaded" ? "" : "hidden"}`}> <span className="tooltiptext top">Upload New File</span>
    <label className={`button centered ${length} ${activeButton === "uploaded" ? "" : "hidden"}`}>
      <i className="material-icons-outlined"> upload_file </i>
      <div className="label"> Upload New File </div>
      <input type="file" hidden multiple onChange={(e) => { handleFilesUpload(jwtToken, setSnackbar, e, setFileNames, fileNames); e.target.value = null }} />
    </label>
  </div>);
}
//Google 

export const renderGoogleFiles = (jwtToken, setSnackbar, setGoogleFileNames, fileNames, setActiveContextFile, setContextSubmenu, activeButton, newChat, getAllThreads, setIsSendDisabled,isSendDisabled, setThreads) => {
  return (
    fileNames.map((filename, index) => {
      const displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
      const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
      return (
        <button key={"google-" + index} className={`button long ${activeButton === "google" ? "" : "hidden"}`}>
          <i className="material-icons-outlined sheets">{googleSheetsLogo}</i>
          <div className="label-container">
            <span className={`label`}>
              {displayTabName}
            </span>
            <span className={`subtitle`}>
              {displaySheetName}
            </span>
          </div>
          <i className="material-icons-outlined clickable" disabled = {isSendDisabled} onClick={() => { setActiveContextFile(filename); setContextSubmenu("files") }} >edit</i>
          <i className="material-icons-outlined clickable" disabled = {isSendDisabled} onClick={() => handleDeleteFile(jwtToken, setSnackbar, filename, setGoogleFileNames, newChat, getAllThreads, setIsSendDisabled, setThreads)} >link_off</i>
        </button>
      );
    })
  );
};


//Hubspot
//Includes auth, direct-export, fields

export const renderHubspotObjects = (hubspotFields, hubspotSelectedObjects, setHubspotSelectedObjects, activeButton) => {
  const objectList = [];
  // console.log("hubspotFields", Object.keys(hubspotFields).map(o => o.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())), hubspotFields);
  Object.keys(hubspotFields).forEach((object, index) => {
    let selected = hubspotSelectedObjects.includes(object);
    if (!Array.isArray(hubspotFields[object])) return;
    objectList.push(
      <div key={"hubspot-object-" + index} className={`button small ${activeButton === "hubspot" ? "" : "hidden"} ${selected ? "active" : ""}`} onClick={() => {
        if (selected) {
          setHubspotSelectedObjects(prevObjects => prevObjects.filter(obj => obj !== object));
        } else {
          setHubspotSelectedObjects(prevObjects => [...prevObjects, object]);
        }
      }}>
        <div className="label">
          {object.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
        </div>
      </div>
    );
  });

  return objectList;
};

export const renderHubspotProperties = (hubspotFields, selectedObjects, selectedFields, setSelectedFields, activeButton, searchQuery, setSearchQuery, selectedFiltered, setSelectedFiltered) => {
  const propertyList = [];
  const handleFieldChange = (object, field) => {
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [object]: {
        ...(selectedFields[object] || {}),
        [field]: !(selectedFields[object] && selectedFields[object][field])
      }
    }));
  }
  const search = (
    <div key="searchdiv" className={`section row ${(activeButton === "hubspot" && selectedObjects.length > 0) ? "" : "hidden"}`}>
      <div className='tooltip chip-short'><span className="tooltiptext top">Filter by Selected</span>
        <button className={`chip short  ${selectedFiltered ? "selected" : ""}`} onClick={() => { setSelectedFiltered(selectedFiltered => !selectedFiltered) }}>
          <i className={`material-icons-outlined ${selectedFiltered ? "selected" : ""}`}>check_circle</i>
          <div className={`label`}>Selected</div>
        </button>
      </div>
      <form key="search" className={`search-bar`}>
        <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </form>

    </div>
  );

  Object.entries(hubspotFields).forEach(([currentObject, properties]) => {
    if (!(selectedObjects.includes(currentObject))) return;
    const filteredFields = selectedFiltered ?

      Object.entries(selectedFields[currentObject])
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key).filter(property =>
          property.toLowerCase().includes(searchQuery.toLowerCase())
        )

      :

      hubspotFields[currentObject].filter(property =>
        property.toLowerCase().includes(searchQuery.toLowerCase())
      );

    filteredFields.forEach((field, index) => {
      let selected = selectedFields[currentObject] && selectedFields[currentObject][field] ? "selected" : ""
      propertyList.push(
        <div key={index + currentObject} className={`tooltip chip-long ${activeButton === "hubspot" ? "" : "hidden"} `}><div className="tooltiptext top"> {field} </div>
          <div className={`chip long ${selected}`} onClick={() => handleFieldChange(currentObject, field)}>
            <i className={`material-icons-outlined ${selected}`}>check_circle</i>
            <div className={`label`}>{field}</div>
          </div>
        </div>
      );
    });
  });
  return (<>
    {search}
    <div className="section row scroll wrap">
      {propertyList}
    </div>
  </>);
};
