const facts = ["The first computer mouse was made of wood.",
  "The term “bug” to describe a glitch in a system originated when a moth caused a malfunction in an early computer.",
  "The average person spends over 4 hours a day on their mobile devices.",
  "Apple’s iPhone has more computing power than NASA’s computers during the moon landing.",
  "The world’s first website is still online. It was created by Tim Berners-Lee and went live in 1991.",
  "The storage capacity of the first commercially available hard drive in 1956 was only 5 megabytes.",
  "The first-ever text message was sent in 1992 and said, “Merry Christmas.”",
  "The first digital camera invented in 1975 weighed 8 pounds and had a resolution of 0.01 megapixels.",
  "The entire source code for Apollo 11’s guidance computer was only 72 kilobytes.",
  "The world’s first computer programmer was a woman named Ada Lovelace, who wrote algorithms for Charles Babbage’s Analytical Engine in the 1840s.",
  "The term “Wi-Fi” doesn’t stand for anything. It is a marketing term coined by a branding company.",
  "The world’s most powerful supercomputer can perform over 1.1 quintillion calculations per second.",
  "In 2018, global e-commerce sales exceeded $2.8 trillion.",
  "The first-ever smartphone, IBM’s Simon, was introduced in 1992 and had features like email, a touchscreen, and a calendar.",
  "The world’s fastest internet speed was recorded at 178 terabits per second, allowing you to download the entire Netflix library in one second.",
  "Nintendo, the renowned video game company, was founded in 1889 as a playing card company.",
  "The first YouTube video was uploaded on April 23, 2005, titled “Me at the zoo.”",
  "Approximately 90% of all the data in the world has been created in the last two years.",
  "The world’s first 1-terabyte hard drive was introduced by IBM in 2007. It was the size of a small refrigerator.",
  "The original Apple Macintosh had only 128 kilobytes of RAM.",
  "The world’s first computer virus was created in 1983 and was called “Elk Cloner.”",
  "The Apollo 11 guidance computer was considered “basic” by today’s standards, with less computing power than a modern-day calculator.",
  "The first 1-gigabyte USB flash drive cost $10,000 in 2000. Today, you can get one for less than $10.",
  "The world’s first 3D-printed car, the Strati, was created in 2014 and took just 44 hours to print.",
  "The total number of mobile phone subscriptions worldwide is estimated to be around 5 billion.",
  "The average person unlocks their smartphone around 150 times a day.",
  "The world’s first video game console, the Magnavox Odyssey, was released in 1972 and could only play simple tennis games.",
  "The first computer-generated movie, “Toy Story,” was released byPixar in 1995.",
  "The world’s first website domain, Symbolics.com, was registered on March 15, 1985.",
  "The world’s largest data center, the Range International Information Group Data Center in China, spans over 6.3 million square feet.",
  "The first-ever commercial text message was sent in 1992, offering a discount on a new phone.",
  "The world’s first 1-megapixel digital camera was released by Kodak in 1986, weighing a whopping 8.8 pounds.",
  "The average person spends about 6 years and 8 months of their life using smartphones.",
  "The term “robot” was coined by Czech writer Karel Čapek in his 1920 play “R.U.R.” It comes from the Czech word “robota,” meaning forced labor.",
  "The world’s first smartphone with a built-in fingerprint scanner was the Toshiba G500, released in 2007.",
  "The IBM 5150, introduced in 1981, is considered the first true personal computer.",
  "The first website with a clickable hyperlink was created in 1991.",
  "The world’s first GPS satellite, Navstar 1, was launched in 1978.",
  "The world’s first touchscreen smartphone was the IBM Simon, which had a monochrome LCD and a stylus.",
  "The most expensive domain name ever sold was “Voice.com,” which was acquired for $30 million in 2019.",
  "The first-ever email was sent by Ray Tomlinson in 1971. It was sent between two computers that were side by side.",
  "The world’s first computer virus for Microsoft Windows, “WinVer 1.4,” was released in 1992.",
  "The first-ever webcam was created at the University of Cambridge to monitor a coffee pot.",
  "The world’s first commercially available mobile phone, the Motorola DynaTAC 8000X, was introduced in 1983 and weighed 2.2 pounds.",
  "The first Apple logo featured Sir Isaac Newton sitting under an apple tree.",
  "The world’s first 1-terabyte solid-state drive (SSD) was introduced in 2009.",
  "The average American household has over 10 connected devices, including smartphones, tablets, and smart home devices.",
  "The first-ever commercial cell phone call was made by Martin Cooper, an engineer at Motorola, in 1973.",
  "The world’s first website had a URL of “info.cern.ch” and was hosted on Tim Berners-Lee’s NeXT computer.",
  "The first-ever computer game, “Spacewar!,” was created in 1962 by Steve Russell.",
  "The world’s first computer, the ENIAC, weighed 27 tons and occupied a space of 1,800 square feet.",
  "The most expensive computer ever built, the IBM Sequoia, cost around $100 million.",
  "The world’s first computer mouse was invented by Douglas Engelbart in 1964 and was made of wood.",
  "The world’s first 1-gigahertz processor, the Intel Pentium III, was introduced in 2000.",
  "The world’s first digital music player, the MPMan F10, was released in 1998 and could store up to 32 megabytes of music.",
  "The first-ever spam email was sent in 1978 to 393 ARPANET users advertising a new computer.",
  "The world’s first commercial digital camera, the Dycam Model 1, was introduced in 1990 and had a resolution of 376x240 pixels.",
  "The first-ever domain name registered was symbolics.com on March 15, 1985.",
  "The world’s first computer virus designed to steal money, called “Zeus,” emerged in 2007 and infected millions of computers.",
  "The world’s first 1-petabyte hard drive, capable of storing 1 million gigabytes of data, was announced in 2018.",
  "The first-ever computer animation, “Hummingbird,” was created by Edward E. Zajac in 1962.",
  "The world’s first portable cell phone, the Motorola DynaTAC 8000X, had a price tag of $3,995 in 1983.",
  "The world’s first smartphone with a built-in camera was the Kyocera VP-210, released in 1999.",
  "The first-ever website to sell a product online was NetMarket, which began selling a CD by Sting in 1994.",
  "The world’s first computer chess program capable of defeating a reigning world champion was IBM’s Deep Blue, which defeated Garry Kasparov in 1997.",
  "The first-ever video uploaded to YouTube was titled “Me at the zoo” and featured co-founder Jawed Karim.",
  "The world’s first barcode was scanned at a supermarket in 1974, and the product was a pack of chewing gum.",
  "The first-ever smartphone with a built-in GPS was the Nokia 6110, released in 1999.",
  "The world’s first touchscreen tablet computer was the GRiDPad, introduced in 1989.",
  "The first-ever video game console, the Magnavox Odyssey, came with plastic overlays that could be placed on the TV screen to create different game environments.",
  "The world’s first 4G smartphone was the HTC Evo 4G, released in 2010.",
  "The world’s first computer printer, the IBM 3800, could print at a speed of 1,100 lines per minute.",
  "The first-ever search engine, Archie, was created in 1990 and indexed FTP sites to help users find specific files.",
  "The world’s first computer modem, the Bell 101, was capable of transmitting data at a speed of 300 bits per second.",
  "The world’s first computer virus to spread over the internet, “ILOVEYOU,” infected millions of computers in 2000.",
  "The first-ever digital voice assistant, IBM’s Shoebox, was introduced in 1961 and could recognize 16 spoken words.",
  "The world’s first gigabit Ethernet switch, the ForeRunner ASIC, was developed by Kalpana in 1992.",
  "The first-ever consumer 3D printer, the RepRap, was capable of printing its own parts and was released in 2005.",
  "The world’s first smartphone with a built-in fingerprint scanner was the Motorola Atrix 4G, released in 2011.",
  "The first-ever public cell phone call from a handheld device was made by Martin Cooper in 1973 to his rival at Bell Labs.",
  "The world’s first home video game console, the Magnavox Odyssey, had no sound capabilities.",
  "The first-ever website to host user-generated content was Geocities, launched in 1994.",
  "The world’s first laptop computer, the Osborne 1, weighed 24 pounds and was introduced in 1981.",
  "The first-ever computer virus to infect the MS-DOS operating system, “Brain,” appeared in 1986.",
  "The world’s first computer-generated feature film, “Toy Story,” was released by Pixar in 1995.",
  "The first-ever computer mouse with a scrollwheel was introduced by Microsoft in 1996.",
  "The world’s first voice recognition system, IBM’s Shoebox, could recognize digits from 0 to 9.",
  "The first-ever computer with a graphical user interface (GUI) was the Xerox Alto, developed in 1973.",
  "The world’s first virtual reality headset, the Sensorama, was created by Morton Heilig in 1962.",
  "The first-ever computer to use transistors instead of vacuum tubes, the IBM 7090, was introduced in 1959.",
  "The world’s first commercial video game, “Pong,” was released by Atari in 1972.",
  "The first-ever social media platform, SixDegrees.com, was launched in 1997 and allowed users to create profiles and make friends online.",
  "The world’s first website to offer online shopping was Amazon.com, which initially sold books and was launched in 1995.",
  "The first-ever computer with a graphical user interface, a mouse, and a keyboard, the Xerox Star 8010, was released in 1981.",
  "The world’s first home video game console with interchangeable cartridges, the Atari 2600, was introduced in 1977.",
  "The first-ever 1-terabyte consumer hard drive, the Hitachi Deskstar 7K1000, was released in 2007.",
  "The world’s first computer to use integrated circuits, the IBM 360, was introduced in 1964.",
  "The first-ever computer virus to spread via email, the “Morris Worm,” infected thousands of computers in 1988.",
  "The world’s first voice-activated virtual assistant, Apple’s Siri, was introduced in 2011 with the release of the iPhone 4S.",
  "The first-ever commercial computer, the UNIVAC I, was introduced in 1951 and weighed around 29,000 pounds."];


const questions = ["How many companies were contacted last month?",
"How many meetings were booked last week?",
"How many deals are expected to close this month?",
"Who sent the most emails this week?",
"Who won the most deals this quarter?",
"Who created the most deals this quarter?",
"What’s the average value of deals in our pipeline today?",
"How many opportunities are in the sales pipeline?",
"Which HubSpot Team created the most tickets this month?",
"Which user completed the most tickets this month?",
"How many tickets are past due?",
"How many deals are past due?",
"What was our win rate last quarter?",
"What’s the average time between deal creation and closed-won?",
"Create a chart showing emails sent per month for the last 6 months",
"Create a chart showing deals created per month for the last 6 months"]


// Function to get a random fact
function getRandomFact() {
  const randomIndex = Math.floor(Math.random() * facts.length);
  return facts[randomIndex];
}

// Function to get a random fact
function getRandomQuestions(numQuestions) {
  const uniqueNumbers = new Set();
  const qset = [];
  while (uniqueNumbers.size < numQuestions) {
    const randomNumber = Math.floor(Math.random() * questions.length);
    uniqueNumbers.add(randomNumber);
  }
  uniqueNumbers.forEach(i => {
    qset.push(questions[i]);
  });
  return qset;
}

export { getRandomFact, getRandomQuestions };
