import React, { useState, useEffect } from 'react';
import { resetPassword } from "aws-amplify/auth";
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import App from '../App';
import logoSmall from "../../assets/small-logo.png";
import "../../styles/screens/Login/Login.css";

function ForgotPassword() {
    const [username, setUsername] = useState('');
    const [status, setStatus] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            const output = await resetPassword({ username: username });
            const { nextStep } = output;
            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                    console.log(username);
                    navigate("/verify", { state: username });
                default:
                    setStatus('failed');
                    return;
            }
        }
        catch (error) {
            console.error('Error resetting password', error);
            setStatus('failed');
            return;
        }
    }

    return (
        <div className='login-container'>
            <div className='login'>
                <img src={logoSmall} alt="Maester" className={`logo`} />
                <form className='login-input-container'>
                    <div className='header-label'> Enter the email address you use for Maester, and we'll send you a password reset link.</div>
                    <input className='login-input' autoComplete="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                </form>
                <button className='login-button' onClick={() => {
                    if (username.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
                        handleSubmit();
                        setStatus("loading");
                    }
                    else {
                        setStatus("failed");
                    }
                }}>Submit</button>
                <label className={`status ${status}`} >
                    {status === "failed" ? "Incorrect Email Formatting" : status === "loading" ? "Email sent!" : " "}
                    <br />
                    {status === "failed" ? "Please check your email and try again." : status === "loading" ? "Please check your inbox for a reset link." : " "}
                </label>
            </div>
        </div>
    );
}

export default ForgotPassword;


