
// This file contains the privacy policy content in Markdown format.
import React from 'react';

const NotFound = () => {
    return (
        <div style={{ color: 'white', width: "80vw", height: "95vh", overflow: 'scroll', fontSize: "14px", padding: '5rem', margin: '5rem' }}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <p>Click <a href="/" style={{ color: '#D6440D'}}>here</a> to go back to the home page.</p>
        </div>
    );
};

export default NotFound;

