import React, { useState } from "react";
import { QuestionMark, DescriptionOutlined } from "@mui/icons-material";
import { hubspotLogo } from "../../assets/icons/hubspotLogo";
import { googleSheetsLogo } from "../../assets/icons/googleSheetsLogo";
import { handleGoogleButtonClick, handleGoogleRefreshClick } from "../../functions/api/Google.jsx";
import { handleHubspotRefresh, handleHubspotSubmit } from "../../functions/api/Hubspot.jsx";
import { renderUploadFileButton, renderFiles, renderGoogleFiles, renderHubspotObjects, renderHubspotProperties } from "../allFiles.jsx";
import KeywordsSubmenu from './KeywordsSubmenu.jsx';
import FormattingSubmenu from './FormattingSubmenu.jsx';
import IntakeSubmenu from './IntakeSubmenu.jsx';


export function ManageData(activeButton, getSelectedDetail, setActiveButton, jwtToken, setSnackbar, setFileNames, fileNames, hubspotConnectionStatus, hubspotSelectedFields, setGoogleFileNames,
    googleFileNames, hubspotFields, hubspotSelectedObjects, setHubspotSelectedObjects, setActiveContextFile, setHubspotSelectedFields, searchQuery, setSearchQuery, selectedFiltered, setSelectedFiltered,
    user, setContextSubmenu, handleOpenPopup, googleData, setGoogleData, newChat, getAllThreads, setIsSendDisabled, isSendDisabled, setThreads) {

    function isActive(button) {
        return activeButton === button ? "active" : "";
    }

    function isHidden(button) {
        return activeButton !== button ? "hidden" : "";
    }

    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const handleClick = async (asyncFunction) => {
        setButtonsDisabled(true);
        try {
            await asyncFunction();
        } catch (error) {
            // Handle error
        } finally {
            setButtonsDisabled(false);
        }
    };


    return (
        <div className={getSelectedDetail("Manage Data") ? "menu" : "menu hidden"}>
            <div>
                <div className="section-header"> Manage Data </div>
                <div className="section row wrap">
                    <div className="tooltip"> <span className={`tooltiptext top ${isActive("hubspot")}`}>Hubspot</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isActive("hubspot")}`} onClick={() => setActiveButton("hubspot")}>
                            <i className="material-icons-outlined hubspot">
                                {hubspotLogo}
                            </i>
                            <div className="label">
                                Hubspot
                            </div>
                        </button>
                    </div>
                    <div className="tooltip"> <span className={`tooltiptext top`}>Google Sheets</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isActive("google")}`} onClick={() => setActiveButton("google")}>
                            <i className="material-icons-outlined sheets">
                                {googleSheetsLogo}
                            </i>
                            <div className="label">
                                Google Sheets
                            </div>
                        </button>
                    </div>
                    <div className="tooltip"> <span className={`tooltiptext top`}>Uploaded Files</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isActive("uploaded")}`} onClick={() => setActiveButton("uploaded")}>
                            <i className="material-icons-outlined" >description</i>
                            <div className="label">
                                Uploaded Files
                            </div>
                        </button>
                    </div>
                    <div className="tooltip"> <span className={`tooltiptext top`}>Request to add a datasource</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isActive("request")}`} onClick={() => { handleOpenPopup("source-request"); }}>
                            <i className="material-icons-outlined">question_mark</i>
                            <div className="label">
                                Request Source
                            </div>
                        </button>
                    </div>
                </div>


                <div className={`section-header ${(!activeButton || activeButton === "request") ? "hidden" : ""}`}> File Options </div>
                <div className="section row wrap">
                    {renderUploadFileButton(jwtToken, setSnackbar, "long", setFileNames, fileNames, activeButton)}
                    <div className={`tooltip ${isHidden("hubspot")}`}>
                        <span className="tooltiptext top">
                            {hubspotConnectionStatus === "fail" ? "Connection Failed, Click to Retry" :
                                hubspotConnectionStatus === "loading" ? "Connecting..." :
                                    hubspotConnectionStatus === "success" ? "Connected" : ""}
                        </span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${hubspotConnectionStatus} ${hubspotConnectionStatus === "fail" ? "" : "nopointer"} ${isHidden("hubspot")}`} onClick={() => { if (hubspotConnectionStatus === "fail") (window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=e5220a0b-bcdd-4b12-b034-1b80a2ff9bd1&redirect_uri=${window.location.origin + '/hubspot-callback'}&scope=tickets%20sales-email-read%20crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.owners.read%20crm.export`); }}>
                            <i className="material-icons-outlined hubspot">
                                {hubspotLogo}
                            </i>

                            <span className={`label`}>
                                {hubspotConnectionStatus === "fail" ? "No Connection" :
                                    hubspotConnectionStatus === "loading" ? "Connecting..." :
                                        hubspotConnectionStatus === "success" ? "Connected" : ""}
                            </span>
                        </button>
                    </div>
                    <div className={`tooltip ${hubspotConnectionStatus === "success" ? "" : "hidden"} ${isHidden("hubspot")}`}>
                        <span className="tooltiptext top">
                            Refresh Hubspot Data
                        </span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${hubspotConnectionStatus !== "success" || activeButton !== "hubspot" ? "hidden" : ""}`} onClick={() => { handleClick(() => handleHubspotRefresh(jwtToken, setSnackbar)) }}>
                            <i className={`material-icons-outlined`}>refresh</i>
                            <div className="label"> Refresh Data</div>
                        </button>
                    </div>
                    <div className={`tooltip ${hubspotConnectionStatus === "success" ? "" : "hidden"}  ${isHidden("hubspot")}`}>
                        <span className="tooltiptext top"> Update Selected Hubspot Properties </span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${hubspotConnectionStatus !== "success" || activeButton !== "hubspot" ? "hidden" : ""}`} onClick={() => { handleClick(() => handleHubspotSubmit(jwtToken, setSnackbar, hubspotSelectedFields)) }}>
                            <i className={`material-icons-outlined`}>arrow_circle_up</i>
                            <div className="label"> Update Selection</div>
                        </button>
                    </div>
                    <div className={`tooltip ${isHidden("google")}`}><span className="tooltiptext top">Upload New Google Sheet</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isHidden("google")}`} onClick={() => { handleClick(() => handleGoogleButtonClick(jwtToken, setSnackbar, handleOpenPopup, setGoogleData)) }}>
                            <i className={`material-icons-outlined sheets`}>{googleSheetsLogo}</i>
                            <div className="label"> Upload Sheet</div>
                        </button>
                    </div>
                    <div className={`tooltip ${isHidden("google")}`}><span className="tooltiptext top">Refresh Sheet Connections</span>
                        <button className={`button short ${buttonsDisabled ? "disabled" : ""} ${isHidden("google")}`} onClick={() => { handleClick(() => handleGoogleRefreshClick(jwtToken, setSnackbar, setGoogleFileNames, newChat, getAllThreads, setIsSendDisabled, setThreads)) }}>
                            <i className={`material-icons-outlined`}>refresh</i>
                            <div className="label"> Refresh Sheets</div>
                        </button>
                    </div>
                </div>
                <div className={`section-header ${hubspotConnectionStatus !== "success" || activeButton !== "hubspot" ? "hidden" : ""}`}> Filter by </div>
                <div className={`section row wrap ${hubspotConnectionStatus !== "success" || activeButton !== "hubspot" ? "hidden" : ""}`}>
                    {renderHubspotObjects(hubspotFields, hubspotSelectedObjects, setHubspotSelectedObjects, activeButton)}
                </div>
                <div className={`section-header ${isHidden("uploaded")}`}> {(fileNames.length > 0) ? "Uploaded Files" : "No Files Uploaded"}  </div>
                <div className={`section column scroll wrap ${isHidden("uploaded")}`}>
                    {fileNames.length === 0 ?
                        ""
                        :
                        renderFiles(jwtToken, setSnackbar, setFileNames, fileNames, setActiveContextFile, setContextSubmenu, activeButton, newChat, getAllThreads, setIsSendDisabled, isSendDisabled, setThreads)}
                </div>
                <div className={`section-header ${isHidden("google")}`}> {(googleFileNames.length > 0) ? "Connected Sheets" : "No Sheets Connected"} </div>
                <div className={`section column scroll wrap ${isHidden("google")}`}>
                    {googleFileNames.length === 0 ?
                        <></>
                        :
                        renderGoogleFiles(jwtToken, setSnackbar, setGoogleFileNames, googleFileNames, setActiveContextFile, setContextSubmenu, activeButton, newChat, getAllThreads, setIsSendDisabled, isSendDisabled, setThreads)}
                </div>
                <div className={`section-header ${(activeButton === "hubspot" && hubspotSelectedObjects.length > 0) ? "" : "hidden"}`}> Properties </div>
                <div className="section row wrap">
                    {renderHubspotProperties(hubspotFields, hubspotSelectedObjects, hubspotSelectedFields, setHubspotSelectedFields, activeButton, searchQuery, setSearchQuery, selectedFiltered, setSelectedFiltered)}
                </div>
            </div>
            <div className="section-detail"> Maester is constantly improving. <br /> <span className="section-detail primary clickable" style={{ textDecoration: "underline" }} onClick={() => handleOpenPopup("feedback")}> leave feedback anytime </span> </div>
        </div>
    );

};


export function renderContext(user, activeContextFile, activeContext, setActiveContext, setActiveContextFile, jwtToken, setSnackbar, handleSetContext, setContextSubmenu,
    keywords, setKeywords, companyColors, setCompanyColors, generalContext, setGeneralContext, chartFormatting, setChartFormatting, getContext, setOrgContext, submenu = "files") {
    let displayName, displaySheetName, displayTabName;;
    if (activeContextFile) {
        displayName = activeContextFile.startsWith("googlesheets_") ? activeContextFile.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : activeContextFile;
        [displaySheetName, displayTabName] = activeContextFile.startsWith("googlesheets_") ? displayName.split('-MAESTERTABNAME-') : ["", ""]
    };


return (
    <>
        <IntakeSubmenu
            user={user}
            companyColors={companyColors}
            setCompanyColors={setCompanyColors}
            generalContext={generalContext}
            setGeneralContext={setGeneralContext}
            setActiveContextFile={setActiveContextFile}
            setContextSubmenu={setContextSubmenu}
            handleSetContext={handleSetContext}
            jwtToken={jwtToken}
            setSnackbar={setSnackbar}
            activeContextFile={activeContextFile}
            activeContext={activeContext}
            submenu={submenu}
            getContext={getContext}
            setOrgContext={setOrgContext}
        />
        <FormattingSubmenu
            submenu={submenu}
            chartFormatting={chartFormatting}
            setChartFormatting={setChartFormatting}
            setActiveContextFile={setActiveContextFile}
            setContextSubmenu={setContextSubmenu}
            handleSetContext={handleSetContext}
            jwtToken={jwtToken}
            setSnackbar={setSnackbar}
            activeContextFile={activeContextFile}
            activeContext={activeContext}
            getContext={getContext}
            setOrgContext={setOrgContext}
        />
        <KeywordsSubmenu
            submenu={submenu}
            keywords={keywords}
            setKeywords={setKeywords}
            setActiveContextFile={setActiveContextFile}
            setContextSubmenu={setContextSubmenu}
            handleSetContext={handleSetContext}
            jwtToken={jwtToken}
            setSnackbar={setSnackbar}
            activeContextFile={activeContextFile}
            activeContext={activeContext}
            getContext={getContext}
            setOrgContext={setOrgContext}
        />
        <div className={activeContextFile && activeContextFile != "Organization Level Context" ? "menu" : "menu hidden"}>
            <div style={{ height: "100%" }}>
                <div className="section scroll column wrap">
                    {
                        activeContextFile && activeContextFile.startsWith("googlesheets_") ?
                            <button className={`button long noclick`}>
                                <i className="material-icons-outlined sheets">{googleSheetsLogo}</i>
                                <div className="label-container">
                                    <span className={`label`}>
                                        {displayTabName}
                                    </span>
                                    <span className={`subtitle`}>
                                        {displaySheetName}
                                    </span>
                                </div>
                            </button>
                            :
                            <button className={`button long noclick`}>
                                <DescriptionOutlined className="start-icon" />
                                <span className={`label`}>
                                    {displayName}
                                </span>
                            </button>
                    }
                </div>
                <div className="section-header"> Edit Context </div>
                <textarea
                    type="text"
                    className="context"
                    placeholder="Use this window to give Maester additional context about your connected data. 
                      This space is useful for things like internal definitions, formulas, or other general 
                      information that you may want to ask about."
                    value={activeContext}
                    onChange={(e) => setActiveContext(e.target.value)}
                />
            </div>
            <div className="submit-buttons">
                <div>
                    <i className="material-icons-outlined" onClick={() => setActiveContextFile(null)} >clear</i>
                    <i className="material-icons-outlined" onClick={async () => { await handleSetContext(jwtToken, setSnackbar, activeContextFile, activeContext); setActiveContextFile(null) }} >check</i>
                </div>
            </div>
        </div >
    </>

)

};
