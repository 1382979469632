import signOutFn from "../../functions/utilities/SignOut.jsx"

export function renderSettings(getSelectedDetail, theme, changeTheme, audioOn, setAudioOn, handleOpenPopup, user, setActiveContextFile, navigate, setContextSubmenu, setSidemenuDetailsSize, modelsModule) {
  return (
    <div className={getSelectedDetail("Settings") ? "menu small-details" : "menu small hidden"}>
      <div>
        <div className="section-header"> Mode </div>
        <div className="section row wrap">
          <div className="tooltip"> <span className="tooltiptext top">Toggle Theme</span>
            {(theme === `light`) ?
              <button className={`button medium`} onClick={() => changeTheme()}>
                <i className={`material-icons-outlined`} >light_mode</i>
                <div className="label">Light Mode</div>
              </button>

              :
              <button className={`button medium`} onClick={() => changeTheme()}>
                <i className={`material-icons-outlined`} >dark_mode</i>
                <div className="label">Dark Mode</div>
              </button>
            }
          </div>
          <div className="tooltip"> <span className="tooltiptext top">Sound On</span>
            {(audioOn) ?
              <button className={`button medium`} onClick={() => setAudioOn(!audioOn)}>
                <i className="material-icons-outlined" >notifications</i>
                <div className="label">Sound is On</div>
              </button>
              :
              <button className={`button medium`} onClick={() => setAudioOn(!audioOn)}>
                <i className="material-icons-outlined" >notifications_off</i>
                <div className="label">Sound is Off</div>
              </button>
            }
          </div>
          <div className="tooltip"> <span className="tooltiptext top">Company Intake</span>
            <button className="button medium" onClick={() => { setActiveContextFile("Organization Level Context"); setContextSubmenu("intake"); setSidemenuDetailsSize(""); }}>
              <i className="material-icons-outlined" >business</i>
              <div className="label">Company Intake </div>
            </button>
          </div>
          <div className="tooltip"> <span className="tooltiptext top">Chart Formatting</span>
            <button className="button medium" onClick={() => { setActiveContextFile("Organization Level Context"); setContextSubmenu("formatting"); }}>
              <i className="material-icons-outlined" >bar_chart</i>
              <div className="label">Chart Formatting </div>
            </button>
          </div>
          {modelsModule}
        </div>
        <div className="section-header"> More </div>
        <div className="section row wrap">
          <div className="tooltip"> <span className="tooltiptext top">Company Key Words</span>
            <button className="button medium" onClick={() => { setActiveContextFile("Organization Level Context"); setContextSubmenu("keywords"); setSidemenuDetailsSize(""); }}>
              <i className="material-icons-outlined" >book</i>
              <div className="label">Company Key Words </div>
            </button>
          </div>
          {/* <div className="tooltip"> <span className="tooltiptext top">Help</span>
            <button className="button medium" onClick={() => { handleOpenPopup("help") }}>
              <i className="material-icons-outlined" >question_mark</i>
              <div className="label">Help</div>
            </button>
          </div> */}
          <div className="tooltip"> <span className="tooltiptext top">Leave Feedback</span>
            <button className="button medium" onClick={() => handleOpenPopup("feedback")}>
              <i className="material-icons-outlined green" >thumb_up</i>
              <div className="label">Leave Feedback</div>
            </button>
          </div>
          <div className="tooltip"> <span className="tooltiptext top">Sign Out</span>
            <button className="button medium" onClick={() => { localStorage.setItem('lastAuthenticatedService', null); signOutFn(navigate); }}>
              <i className="material-icons-outlined red" >logout</i>
              <div className="label">Log Out</div>
            </button>
          </div>
        </div>
        <div className="section-detail primary"> Account Type: {user.role} </div>
        {/* <div className="section-detail"> Maester is constantly improving. <br/> <span className="section-detail primary clickable" style={{ textDecoration: "underline" }} onClick={() => handleOpenPopup("feedback")}> leave feedback anytime </span> </div> */}
      </div>
    </div>
  );
};
