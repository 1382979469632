import { post } from 'aws-amplify/api';

export const rateAnswer = (jwtToken, threadID, message_id, isThumbUp) => {
    post({
        apiName: 'MaesterV3API',
        path: '/api/v3/rate-answer',
        options: {
            headers: { Authorization: jwtToken },
            body: {
                thread_id: threadID,
                message_id: message_id,
                is_thumbs_up: isThumbUp,
            }
        }
    }).response.catch((error) => {
        console.error(error);
        return false;
    });
    return true;
};


export const handleGeneralFeedback = (setSnackbar, feedback, submitClicked) => {
    var currentdate = new Date();
    setSnackbar("sending feedback...", "loading");
    var datetime = currentdate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    const formData = new FormData();
    formData.append('Datetime', datetime);             // Append datetime value
    formData.append('Feedback', feedback);             // Append feedback value

    // Debug: Log form data entries
    for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
    }

    fetch("https://script.google.com/macros/s/AKfycbwyvCnmV6vNqv6RkgqCPoANtjpSwDft9Ju7e9ZqN6jg3-3e-U0x1o7mZ85qLyztYU8_-Q/exec", {
        method: "POST",
        contentType: "multipart/form-data",
        body: formData
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setSnackbar("Feedback sent!", "complete");
            return data;
        })
        .catch(error => {
            console.error('Failed to fetch:', error);
            setSnackbar("Failed to send feedback", "error");
            throw error;
        });
}


export const handleSendFeedback = (setSnackbar, chatHistory, idealFileNames, isThumbUp, feedback, submitClicked = true) => {
    var currentdate = new Date();
    setSnackbar("sending feedback...", "loading");
    var datetime = currentdate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    const chatHistoryString = chatHistory.map(chat => `${chat.role}: ${chat.content}`).join('\n');
    // const selectedFileNamesString = selectedFileNames.map(filename => `${filename}`).join('\n');
    const idealFileNamesString = idealFileNames.map(filename => `${filename}`).join('\n');

    // Create a new FormData object
    const formDatab = new FormData();
    formDatab.append('ThumbUp', isThumbUp);               // Append thumbUp value
    formDatab.append('Datetime', datetime);             // Append datetime value
    formDatab.append('Feedback', submitClicked ? feedback : "");             // Append feedback value
    formDatab.append('ChatHistory', chatHistoryString); // Append chatHistory value
    formDatab.append('ChatGPTSelectedFiles', "");             // Append files value
    formDatab.append('UserSelectedFiles', submitClicked ? idealFileNamesString : "");             // Append files value

    for (let pair of formDatab.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }
    fetch("https://script.google.com/macros/s/AKfycbwyvCnmV6vNqv6RkgqCPoANtjpSwDft9Ju7e9ZqN6jg3-3e-U0x1o7mZ85qLyztYU8_-Q/exec", {
        method: "POST",
        contentType: "multipart/form-data",
        body: formDatab
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setSnackbar("Feedback sent!", "complete");
            return data;
        })
        .catch(error => {
            console.error('Failed to fetch:', error);
            setSnackbar("Failed to send feedback", "error");
            throw error;
        });


};