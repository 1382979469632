import React from 'react';

const IntakeSubmenu = ({ user, companyColors, setCompanyColors, generalContext, setGeneralContext, setActiveContextFile, setContextSubmenu, handleSetContext, jwtToken, setSnackbar, activeContextFile, activeContext, getContext, setOrgContext, submenu }) => {

    const updateContextAndHandleSet = async () => {
        setSnackbar("Updating company information", "loading");
        // Convert generalContext back to a string
        const generalContextStr = JSON.stringify(generalContext, null, 0);
        console.log('generalContextStr:', generalContextStr);

        // Convert companyColors to a string
        const companyColorsStr = JSON.stringify(companyColors, null, 0);
        console.log('companyColorsStr:', companyColorsStr);

        // Remove newlines from chartFormattingStr and companyColorsStr
        const formattedgeneralContextStr = generalContextStr.replace(/\n/g, '');
        const formattedCompanyColorsStr = companyColorsStr.replace(/\n/g, '');
        console.log('formattedgeneralContextStr:', formattedgeneralContextStr);
        console.log('formattedCompanyColorsStr:', formattedCompanyColorsStr);

        // Define the pattern to match the general context and company colors section
        const companyColorsPattern = /COMPANY COLORS:\n\s*(\[[^\]]*\])/;
        const generalContextPattern = /GENERAL CONTEXT:\n\s*"([^"]*)"/;
        console.log('generalContextPattern:', generalContextPattern);
        console.log('companyColorsPattern:', companyColorsPattern);

        // Log the matches
        const generalContextMatch = activeContext.match(generalContextPattern);
        const companyColorsMatch = activeContext.match(companyColorsPattern);
        console.log("activeContext:", activeContext);
        console.log('generalContextMatch:', generalContextMatch);
        console.log('companyColorsMatch:', companyColorsMatch);

        // Replace the relevant part of activeContext
        let updatedActiveContext = activeContext.replace(generalContextPattern, `GENERAL CONTEXT:\n${formattedgeneralContextStr}`);
        updatedActiveContext = updatedActiveContext.replace(companyColorsPattern, `COMPANY COLORS:\n${formattedCompanyColorsStr}`);
        console.log('updatedActiveContext:', updatedActiveContext);

        // Call handleSetContext with the updated activeContext
        await handleSetContext(jwtToken, setSnackbar, activeContextFile, updatedActiveContext);
        setOrgContext(updatedActiveContext);
        setActiveContextFile(null);
        setContextSubmenu("settings");
        setSnackbar("Update completed!", "complete")
    };


    return (
        <div key="intake" className={submenu === "intake" ? "menu" : "menu hidden"}>
            <div className="section column wrap">
                <div className="section-header"> Company Information for <span className="primary" style={{ fontWeight: "bold" }}>{user.organization ?? "Your Organization"}</span> </div>
                <div className="section-header"> Company Colors </div>
                <input
                    type="text"
                    placeholder="Primary Hex Code"
                    value={companyColors[0] || ""}
                    onChange={(e) => {
                        companyColors[0] = e.target.value;
                        setCompanyColors([...companyColors]);
                    }}
                    onBlur={(e) => {
                        if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(e.target.value)) {
                            companyColors[0] = "#";
                            setCompanyColors([...companyColors]);
                        }
                    }}
                    maxLength={7}
                />
                <input
                    type="text"
                    placeholder="Secondary Hex Code"
                    value={companyColors[1] || ""}
                    onChange={(e) => {
                        companyColors[1] = e.target.value;
                        setCompanyColors([...companyColors]);
                    }}
                    onBlur={(e) => {
                        if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(e.target.value)) {
                            companyColors[1] = "#";
                            setCompanyColors([...companyColors]);
                        }
                    }}
                    maxLength={7}
                />
                <input
                    type="text"
                    placeholder="Tertiary Hex Code"
                    value={companyColors[2] || ""}
                    onChange={(e) => {
                        companyColors[2] = e.target.value;
                        setCompanyColors([...companyColors]);
                    }}
                    onBlur={(e) => {
                        if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(e.target.value)) {
                            companyColors[2] = "#";
                            setCompanyColors([...companyColors]);
                        }
                    }}
                    maxLength={7}
                />

                <div className="section-header"> Company General Context </div>
                <textarea
                    className="text tall"
                    type="text"
                    placeholder="Add anything you'd like Maester to understand about your organization."
                    value={generalContext || ""}
                    onChange={(e) => {
                        setGeneralContext(e.target.value);
                    }}
                />
            </div>
            <div className="submit-buttons">
                <div>
                    <i className="material-icons-outlined" onClick={() => { setActiveContextFile(null); setContextSubmenu(null); }} >clear</i>
                    <i className="material-icons-outlined" onClick={() => updateContextAndHandleSet()} >check</i>
                </div>
            </div>
        </div>
    );
};

export default IntakeSubmenu;