// Snackbar.jsx
import React from 'react';
import { Clear } from "@mui/icons-material";

const Snackbar = ({ snackbarStatus, snackbarMessage, setSnackbarStatus }) => (
  <div className={`snackbar ${snackbarStatus}`}>
    <div className={`label`}>{snackbarMessage}</div>
    <Clear className="icon" onClick={() => setSnackbarStatus("hidden")} />
  </div>
);

export default Snackbar;