import React, { useState } from 'react';

const KeywordsSubmenu = ({ submenu, keywords, setKeywords, setActiveContextFile, setContextSubmenu, handleSetContext, jwtToken, setSnackbar, activeContextFile, getContext, setOrgContext, activeContext }) => {
    // Create a state variable for keywordsVisibility

    const [keywordsVisibility, setKeywordsVisibility] = useState(
        Object.keys(keywords).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    function hideAllDefinitions() {
        const newKeywordsVisibility = Object.keys(keywordsVisibility).reduce((acc, key) => ({ ...acc, [key]: false }), {});
        setKeywordsVisibility(newKeywordsVisibility);
    }

    // Function to toggle visibility
    const toggleVisibility = (key) => {
        setKeywordsVisibility(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };

    const [newKeyword, setNewKeyword] = useState("");
    const [newDefinition, setNewDefinition] = useState("");

    const updateContextAndHandleSet = async () => {
        setSnackbar("Updating key words", "loading");
        // Convert keywords back to a string
        const keywordsStr = JSON.stringify(keywords, null, 0);

        // Remove newlines from keywordsStr
        const formattedKeywordsStr = keywordsStr.replace(/\n/g, '');

        // Define the pattern to match the keywords section
        const keywordsPattern = /(KEYWORDS:\n\s*)({.*})/;

        // Replace the relevant part of activeContext
        const updatedActiveContext = activeContext.replace(keywordsPattern, `$1${formattedKeywordsStr}`);

        // Call handleSetContext with the updated activeContext
        await handleSetContext(jwtToken, setSnackbar, activeContextFile, updatedActiveContext);
        setOrgContext(updatedActiveContext);
        setActiveContextFile(null);
        hideAllDefinitions();
        setContextSubmenu("settings");
        setSnackbar("Update completed!", "loading");
    };

    // In your component
    <i className="material-icons-outlined" onClick={updateContextAndHandleSet}>check</i>

    return (
        <div key="keywords" className={submenu === "keywords" ? "menu" : "menu hidden"}>
            <div className="section column wrap">
                <div className="section-header"> Company Key Words </div>
                <input
                    type="text"
                    placeholder="Key Word"
                    value={newKeyword}
                    onChange={(e) => {
                        if (!e.target.value.includes('}') && !e.target.value.includes('\n')) {
                            setNewKeyword(e.target.value);
                        }
                    }}
                />
                <textarea
                    className="definition tall"
                    type="text"
                    placeholder="Definition"
                    value={newDefinition}
                    onChange={(e) => {
                        if (!e.target.value.includes('}') && !e.target.value.includes('\n')) {
                            setNewDefinition(e.target.value);
                        }
                    }}
                />
                <div className="submit-buttons">
                    <div>
                        <i className="material-icons-outlined" onClick={() => { setNewKeyword(""); setNewDefinition(""); }} >clear</i>
                        <i className="material-icons-outlined" onClick={async () => { if (newKeyword && newDefinition) { keywords[newKeyword] = newDefinition; setNewKeyword(""); setNewDefinition(""); } }} >check</i>
                    </div>
                </div>
                <hr />
                {Object.entries(keywords).map(([key, value]) => (
                    <div key={key} style={{ width: "100%" }}>
                        <div className="keyword-buttons"  onClick={() => { toggleVisibility(key); }}>
                            <div type="text" className="keyword" onChange={(e) => console.log(e.target.value)} >{key}</div>
                            <i className="material-icons-outlined" onClick={() => {
                                setKeywords(prevKeywords => {
                                    const { [key]: _, ...newKeywords } = prevKeywords;
                                    return newKeywords;
                                });

                                setKeywordsVisibility(prevKeywordsVisibility => {
                                    const { [key]: _, ...newKeywordsVisibility } = prevKeywordsVisibility;
                                    return newKeywordsVisibility;
                                });
                            }}>delete</i>
                            <i className="material-icons-outlined" onClick={() => { toggleVisibility(key); }} >edit</i>
                        </div>
                        <textarea
                            className={`definition tall ${keywordsVisibility[key] ? '' : 'hidden'}`}
                            type="text"
                            placeholder="Definition"
                            value={value}
                            onChange={(e) => {
                                if (!e.target.value.includes('}') && !e.target.value.includes('\n')) {
                                    keywords[key] = e.target.value;
                                    setKeywords({ ...keywords });
                                }
                            }}
                        />
                        <hr />
                    </div>
                ))}
            </div>
            <div className="submit-buttons">
                <div>
                    <i className="material-icons-outlined" onClick={() => { setActiveContextFile(null); setContextSubmenu(null); hideAllDefinitions(); }} >clear</i>
                    <i className="material-icons-outlined" onClick={() => updateContextAndHandleSet()} >check</i>
                </div>
            </div>
        </div>
    );
};

export default KeywordsSubmenu;