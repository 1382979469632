import React, { useState, useEffect } from 'react';
import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import logoSmall from "../../assets/small-logo.png";
import "../../styles/screens/Login/Login.css";

function FirstSignIn() {
    const locationState = useLocation().state;
    const { username, tempPassword } = locationState || {};
    const [password, setPassword] = useState('');
    const [passwordVerify, setPasswordVerify] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordVerify, setShowPasswordVerify] = useState(false);
    const [status, setStatus] = useState('');
    const [isChecked, setIsChecked] = useState(false); // New state variable
    const navigate = useNavigate();

    useEffect(() => {
        if (!username || !tempPassword) {
            // navigate("/login");
        }
    }, [username, tempPassword, navigate]);

    const handleTogglePassword = (verify = false) => {
        if (verify) {
            setShowPasswordVerify(!showPasswordVerify);
        } else {
            setShowPassword(!showPassword);
        }
    };

    const handleSubmit = async () => {
        if (password === passwordVerify) {
            try {
                const output = await signIn({ username, tempPassword });
                await confirmSignIn({
                    challengeResponse: password,
                });
                navigate('/login');
            } catch (error) {
                console.error('Error confirming new password', error);
                setStatus('failed');
            }
        } else {
            setStatus('failed');
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        console.log(e.target.checked);
    };

    return (
        <div className='login-container'>
            <div className='login'>
                <img src={logoSmall} alt="Maester" className={`logo`} />
                <form className='login-input-container'>
                    <div className='header-label'>Welcome! Create a new password to log into Maester.</div>
                    <div className='password-input-container'>
                        <input className='login-input' autoComplete='new-password' type={showPassword ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                        <button className='password-button material-icons-outlined' onClick={(e) => { e.preventDefault(); handleTogglePassword(); }}>
                            {showPassword ? "visibility" : "visibility_off"}
                        </button>
                    </div>
                    <div className='password-input-container'>
                        <input className='login-input' autoComplete='new-password' type={showPasswordVerify ? "text" : "password"} name="passwordVerify" value={passwordVerify} onChange={(e) => setPasswordVerify(e.target.value)} placeholder="Verify Password" />
                        <button className='password-button material-icons-outlined' onClick={(e) => { e.preventDefault(); handleTogglePassword(true); }}>
                            {showPasswordVerify ? "visibility" : "visibility_off"}
                        </button>
                    </div>
                </form>
                <div className='terms-checkbox'>
                    <button onClick={() => setIsChecked(isChecked => !isChecked)} className={`checkmark ${isChecked ? 'clicked' : ''}`}></button>
                    <label className='header-label' htmlFor='terms'> I accept the terms of <a href='/privacy'>Maester's Privacy Policy</a></label>
                </div>
                <button className='login-button' disabled={!isChecked} onClick={handleSubmit}>Submit</button>
                <label className={`status ${status}`} >
                    {status === "failed" ? "Password Change Failed" : " "}
                    <br />
                    {status === "failed" ? "Please make sure your passwords match and try again." : " "}
                    <br /><br />
                    {status === "failed" ? "Passwords must have one of each: uppercase letter, lowercase character, number, special character, and be longer than 8 characters." : " "}
                </label>
            </div>
        </div>
    );
}

export default FirstSignIn;
