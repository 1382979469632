import React from 'react'
import { useState } from 'react'
import '../styles/components/pop-up/pop-up.css'
import { Check, Clear } from "@mui/icons-material";
import { handleSendFeedback, handleGeneralFeedback } from "../functions/api/Feedback";
import { googleSheetsLogo } from "../assets/icons/googleSheetsLogo";
import { hubspotLogo } from '../assets/icons/hubspotLogo';


function FeedbackPopup({ popupClass, isPopupOpen, setSelectedFiles, handleClosePopup, feedback, setFeedback, sendFeedback }) {
    return (
        <div className={`pop-up ${popupClass} ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> Thank you for your Feedback! </div>
                <Clear className="close-icon" onClick={() => { setSelectedFiles([]); handleClosePopup(false); }} />
            </div>
            <div className="content">
                <textarea className="text-input" placeholder='Use this space to share your thoughts.' value={feedback} onChange={(e) => { e.preventDefault(); setFeedback(e.target.value); }}></textarea>
            </div>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => sendFeedback(true, popupClass)} />
            </div>
        </div>
    );
}

function RequestSourcePopup({ popupClass, isPopupOpen, setSelectedFiles, handleClosePopup, feedback, setFeedback, sendFeedback }) {
    return (
        <div className={`pop-up ${popupClass} ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> Request a Source </div>
                <Clear className="close-icon" onClick={() => { setSelectedFiles([]); handleClosePopup(false); }} />
            </div>
            <div className="content">
                <textarea className="text-input" placeholder='Which data source(s) would you like to see on Maester?' value={feedback} onChange={(e) => { e.preventDefault(); setFeedback(e.target.value); }}></textarea>
            </div>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => sendFeedback(true, popupClass)} />
            </div>
        </div>
    );
}

function GooglePopup({ popupClass, isPopupOpen, setSelectedFiles, handleClosePopup, renderFiles, sendFeedback }) {
    return (
        <div className={`pop-up ${popupClass} ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> Upload Sheet Tabs </div>
                <Clear className="close-icon" onClick={() => { setSelectedFiles([]); handleClosePopup(false); }} />
            </div>
            <div className="content">
                <div className="title"> Which tabs would you like to import? </div>
                <div className="container">
                    {renderFiles()}
                </div>
            </div>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => sendFeedback(true, popupClass)} />
            </div>
        </div>
    );
}

function ThumbsPopup({ popupClass, isThumbUp, isPopupOpen, setSelectedFiles, handleClosePopup, feedback, setFeedback, fileNames, renderFiles, sendFeedback }) {
    return (
        <div className={`pop-up ${popupClass} ${isThumbUp ? "up" : "false"} ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> Thank you for your Feedback! </div>
                <Clear className="close-icon" onClick={() => { setSelectedFiles([]); handleClosePopup(false); }} />
            </div>
            <div className="content">
                {isThumbUp ?
                    <textarea className="text-input" placeholder='What did Maester do well?' value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
                    :
                    <>
                        <textarea className="text-input" placeholder='What can Maester do better?' value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
                        {fileNames.length > 0 ?
                            <>
                                <div className="title"> What should have been used to answer your question? (Optional) </div>
                                <div className="container">
                                    {renderFiles()}
                                </div>
                            </>
                            :
                            null}
                    </>}
            </div>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => sendFeedback(true, popupClass)} />
            </div>
        </div>
    );
}

function ContextPopup({ popupClass, sendFeedback, isPopupOpen, handleClosePopup, renderFiles, sendWithContextFiles, setSendWithContextFiles }) {
    return (
        <div className={`pop-up ${popupClass} ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> Which files would you like Maester to analyze with your request? </div>
                <Clear className="close-icon" onClick={() => { handleClosePopup(false); }} />
            </div>
            <div className="content">
                <div className="container">
                    {renderFiles(sendWithContextFiles, setSendWithContextFiles)}
                </div>
            </div>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => sendFeedback(true, popupClass)} />
            </div>
        </div>
    );
}

function HelpPopup({ isPopupOpen, handleClosePopup }) {
    return (
        <div className={`pop-up help ${isPopupOpen ? "" : "hidden"}`}>
            <div className="header">
                <div className="title"> How to Make the Most of Maester </div>
                <Clear className="close-icon" onClick={() => { handleClosePopup(false); }} />
            </div>
            <iframe className="content" src="https://docs.google.com/document/d/e/2PACX-1vRgWKldzKmFfh2KGqqle5xvyeY06tysgPNsE9xd4KmM5ocuCIWUfGnDEe-egf72Nb7R9X9SeAZ7LOjL/pub?embedded=true"></iframe>
            <div className="footer">
                <Check className='confirm-icon' onClick={() => { handleClosePopup(false); }} />
            </div>
        </div>

    );
}

function PopUp({ setSnackbar, isPopupOpen, handleClosePopup, messages, setGoogleData, popupClass, isThumbUp, setSendWithContextFiles, sendWithContextFiles, fileNames = [], hubspotFileNames = [], googleData = { data: {}, tabNames: [], selectedTabNames: [] } }) {
    const [feedback, setFeedback] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const toggleFileSelection = (fileSetter = setSelectedFiles, filename) => {
        fileSetter(prevSelectedFiles => {
            if (prevSelectedFiles.includes(filename)) {
                return prevSelectedFiles.filter(file => file !== filename);
            } else {
                return [...prevSelectedFiles, filename];
            }
        });
    };


    const sendFeedback = (submitClicked = true, popupClass = "") => {
        if (popupClass === "feedback" || popupClass === "source-request") {
            handleGeneralFeedback(setSnackbar, feedback, submitClicked);
        }
        else if (popupClass === "google" && submitClicked) {
            setGoogleData({ ...googleData, selectedTabNames: [...selectedFiles] });
        }
        else if (popupClass === "thumbs") {
            handleSendFeedback(setSnackbar, messages, selectedFiles, isThumbUp, feedback, submitClicked);
        }
        setSelectedFiles([]);
        setFeedback("");
        handleClosePopup(submitClicked);
    };

    const renderFiles = (initialFilesToSelect = selectedFiles, fileSetter = setSelectedFiles) => {
        const filelist = [];
        if (popupClass === "google" && googleData.tabNames.length > 0) {
            googleData.tabNames.forEach((filename, index) => {
                let selected = initialFilesToSelect.includes(filename);
                const displayName = filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "");
                filelist.push(
                    <div key={"file" + index} className={`button medium ${selected ? "selected" : ""}`} onClick={() => toggleFileSelection(fileSetter, filename)}>
                        <i className="material-icons-outlined sheets">{googleSheetsLogo}</i>
                        <span className={`label`}>
                            {displayName}
                        </span>
                    </div>
                );
            });
        }

        else {
            fileNames.forEach((filename, index) => {
                let selected = initialFilesToSelect.includes(filename);
                const displayName = filename.startsWith("googlesheets_") ? filename.replace("googlesheets_", "").replace("_", " ").replace(".csv", "") : filename;
                const [displaySheetName, displayTabName] = displayName.split('-MAESTERTABNAME-');
                const fileObject = filename.startsWith("googlesheets_") ?
                    <div key={"file" + index} className={`button medium ${selected ? "selected" : ""}`} onClick={() => toggleFileSelection(fileSetter, filename)}>
                        <i className="material-icons-outlined sheets">{googleSheetsLogo}</i>
                        <div className="label-container">
                            <span className={`label`}>
                                {displayTabName}
                            </span>
                            <span className={`subtitle`}>
                                {displaySheetName}
                            </span>
                        </div>
                    </div>
                    :
                    <div key={"file" + index} className={`button medium ${selected ? "selected" : ""}`} onClick={() => toggleFileSelection(fileSetter, filename)}>
                        <i className="material-icons-outlined" >description</i>
                        <span className={`label`}>
                            {displayName}
                        </span>
                    </div>
                filelist.push(
                    fileObject
                );
            });
            hubspotFileNames.forEach((filename, index) => {
                let selected = initialFilesToSelect.includes(filename+".csv");
                const displayName = filename.startsWith("hubspot_") ? filename.replace("hubspot_", "").replace("_", " ").replace(".csv", ""): filename;
                const formattedDisplayName = displayName.charAt(0).toUpperCase() + displayName.slice(1).toLowerCase() + " Data Source";
                const fileObject =
                    <div key={"hubspot-file" + index} className={`button medium ${selected ? "selected" : ""}`} onClick={() => toggleFileSelection(fileSetter, filename+".csv")}>
                        <i className="material-icons-outlined hubspot">{hubspotLogo}</i>
                        <span className={`label`}>
                            {formattedDisplayName}
                        </span>
                    </div>
                filelist.push(
                    fileObject
                );
            });

        }
        return filelist;
    }

    const renderPopup = () => {
        switch (popupClass) {
            case 'google':
                return <GooglePopup popupClass={popupClass} isPopupOpen={isPopupOpen} setSelectedFiles={setSelectedFiles} handleClosePopup={handleClosePopup} renderFiles={renderFiles} sendFeedback={sendFeedback} />;
            case 'feedback':
                return <FeedbackPopup popupClass={popupClass} isPopupOpen={isPopupOpen} setSelectedFiles={setSelectedFiles} handleClosePopup={handleClosePopup} feedback={feedback} setFeedback={setFeedback} sendFeedback={sendFeedback} />;
            case 'source-request':
                return <RequestSourcePopup popupClass={popupClass} isPopupOpen={isPopupOpen} setSelectedFiles={setSelectedFiles} handleClosePopup={handleClosePopup} feedback={feedback} setFeedback={setFeedback} sendFeedback={sendFeedback} />;
            case 'thumbs':
                return <ThumbsPopup popupClass={popupClass} isThumbUp={isThumbUp} isPopupOpen={isPopupOpen} setSelectedFiles={setSelectedFiles} handleClosePopup={handleClosePopup} feedback={feedback} setFeedback={setFeedback} fileNames={fileNames} renderFiles={renderFiles} sendFeedback={sendFeedback} />;
            case 'context':
                return <ContextPopup popupClass={popupClass} isThumbUp={isThumbUp} isPopupOpen={isPopupOpen} handleClosePopup={handleClosePopup} feedback={feedback} setFeedback={setFeedback} fileNames={fileNames} renderFiles={renderFiles} sendFeedback={sendFeedback} sendWithContextFiles={sendWithContextFiles} setSendWithContextFiles={setSendWithContextFiles} />;
            case 'help':
                return <HelpPopup popupClass={popupClass} isPopupOpen={isPopupOpen} handleClosePopup={handleClosePopup} />;
            default:
                return null;
        }
    };

    return renderPopup();
}

export default PopUp

